jQuery(document).ready(function($) {

  // clear and restore form input values
  var el = $('input[type=text], input[type=email], textarea, input.header-search');
  el.focus(function(e) {
    if (e.target.value === e.target.defaultValue)
      e.target.value = '';
  });
  el.blur(function(e) {
    if (e.target.value === '')
      e.target.value = e.target.defaultValue;
  });

  // Touch menu
  $('.touch-menu-open').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.touch-menu').addClass('active');
  });

  $('.touch-menu-close').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('.touch-menu').removeClass('active');
  });

  $('.touch-menu .parent .indicator').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).parent().parent().toggleClass('active');
  });

  // Content tabs
  $('.tabs .tab').click(function(e){
    var tab = "#" + $(this).data('tab');
    $(this).addClass('active');
    $(this).siblings().removeClass('active');
    $('.tab-content').not(tab).removeClass('active');
    $(tab).addClass('active');
  });

  // Content accordions
  $('.accordion-heading').click(function(e) {
    var accordion = $(this).parent();
    if ($(accordion).hasClass('active')) {
      $(accordion).removeClass('active');
    } else {
      $('.accordion').not(accordion).removeClass('active');
      $(accordion).addClass('active');
    }
  });

  // Alert Bar close button
  $('.close-alert').click(function(e){
    e.preventDefault();
    $('.alert-bar').hide();
    sessionStorage.hideAlert =  true;
  });
});

if (!sessionStorage.hideAlert) {
  $('.alert-bar').show();
}
